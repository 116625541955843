<template>
    <v-sheet class="i-field">
        
        <label :for="id">{{ $t(label) }}</label>
        <v-text-field
            :ref="'textField' + id"
            :modelValue="modelValue"
            :id="id"
            :name="id"
            :aria-describedby="id"
            :variant="variant == ''? variant : 'outlined' " 
            :density="density == ''? density : 'compact' " 
            :type="type"
            :rules="rules"
            validate-on="submit lazy"
            :append-inner-icon="appendInnerIcon"
            @click:append-inner="clickIcon"
            :placeholder="placeholder != null? $t(placeholder) : undefined"
            :persistent-placeholder="persistentPlaceholder"
            @update:model-value="(val: any) => onUpdated(val)"
            :readonly="readonly"
            :disabled="disabled"
            autocomplete="off"
            >
        </v-text-field>
    </v-sheet>
</template>

<script>
export default {
    props: ["label", "modelValue", "id", "placeholder", "type", "rules", "variant", "density", "persistentPlaceholder", "screenReader", "fixedLabel", "readonly", "disabled", "appendInnerIcon"],
    methods: {
        onUpdated: function(value) {
            this.$refs['textField' + this.id].resetValidation()
            this.$emit("update:modelValue", value)
        },
        clickIcon() {
            this.$emit('click:append-inner')
        },
        validate() {
           return this.$refs['textField' + this.id].validate()
        }
    }
}
</script>

<style>
</style>