import './assets/main.css'

import { createApp } from 'vue'
import App from './App.vue'
import { registerPlugins } from './plugins'

const app = createApp(App)

registerPlugins(app)

Date.prototype.addDays = function(days: number) {
    this.setDate(this.getDate() + days)

    return this
};

Date.prototype.addMonths = function(months: number) {
    this.setMonth(this.getMonth() + months)

    return this
};

Date.prototype.addYears = function(years: number) {
    this.setFullYear(this.getFullYear() + years, this.getMonth(), this.getDate())

    return this
};

Date.prototype.toString = function() {
    // console.log("toString")
    // console.log(this.toISOString().split('T')[1])
    // console.log(this.toISOString().split('T')[1] == "00:00:00.000Z")
    // console.log(navigator.language)
    // if(this.toISOString().split('T')[1] == "00:00:00.000Z")
    //     return this.toLocaleDateString()
    // else 
    var intlSettings = Intl.DateTimeFormat().resolvedOptions()
    return this.toLocaleString(intlSettings.locale, {timeZone: intlSettings.timeZone})
} 

Date.prototype.getAge = function() {
    var today = new Date();
    var age = today.getFullYear() - this.getFullYear();
    var m = today.getMonth() - this.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < this.getDate())) {
        age--;
    }
    return age;
};

Date.prototype.equals = function(comparison: Date) {
    return this.getTime() == comparison.getTime()
};

app.mount('#app')
