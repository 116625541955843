<template>

    <v-dialog
        class="v-dialog--timeout"
        :modelValue="countdown < 30"
        >
        <template v-if="isDev" v-slot:activator="{ props: activatorProps }">
            <v-btn
            v-bind="activatorProps"
            color="surface-variant"
            text="Open Dialog"
            variant="flat"
            ></v-btn>
        </template>

        <template v-slot:default="{ isActive }">
            <v-card>
                <v-card-title>
                    <h2 class="dialog-title">You are about to time out!</h2>
                </v-card-title>
                
                <v-card-text>
                    <v-row dense>
                        <div class="clock-row">
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 45 45" style="enable-background:new 0 0 45 45;" xml:space="preserve">
                                <line class="to-loader-pointer" x1="23" y1="22.8" x2="23" y2="7"/>
                                <line class="to-loader-line" x1="23" y1="22.8" x2="33.2" y2="22.8"/>
                                <circle class="to-loader-circle" cx="23" cy="22.8" r="20.6"/>
                                <circle class="to-loader-center" cx="23" cy="22.8" r="3.4"/>
                            </svg>
                        </div>
                        <div class="countdown">{{ countdown }} seconds remaining</div>
                   </v-row>
                </v-card-text>

                <v-card-actions
                    class="dialog-btn-row"
                >
                <v-btn
                    type="submit"
                    class="i-btn i-btn--primary"
                    color="primary"
                    @click="refreshToken"
                    >Stay logged in </v-btn>
                </v-card-actions>
            </v-card>
        </template>

    </v-dialog>
</template>

<script setup lang="ts">

</script>

<script lang="ts">
import Api from '@/common/api/Api';
import { useAuthenticationStore } from '@/stores/authentication';

export default {
    name: 'cqs-timeout-warning',
    props: ["isDev"],
    created() {
        setInterval(() => {
            var store = useAuthenticationStore()
            this.countdown = store.getTokenExpirySeconds();

            this.countdown = Math.max(this.countdown, 0);


            if(this.countdown <= 0 && store.isAuthenticated) {
                store.logOut()
                this.$router.push({name: 'login'})
            }
        }, 1000);
    },
    methods: {
        async refreshToken() {
            if(!this.isDev) {
                await Api.refreshToken()
                // var newExpiry = useAuthenticationStore().getTokenExpirySeconds
                // this.timer = newExpiry
            }
        }
    },
    components: {},
    data() { 
        return {
            countdown: 999
        } 
    }
}
</script>

<style scoped>

</style>